// prettier-ignore
export default {
  'apple-id': 'Apple',
  'azuread-v2-tenant-oauth2': 'Azure AD',
  'google-openidconnect': 'Google',
  'microsoft-graph': 'Microsoft',
  actions: 'Aktionen',
  activate: 'Aktivieren',
  add: 'Hinzufügen',
  addGroup: 'Gruppe hinzufügen',
  addUser: 'Benutzer hinzufügen',
  anErrorOccurred: 'Ein Fehler ist aufgetreten:',
  applications: 'Anwendungen',
  assignGroups: 'Zu Gruppen hinzufügen',
  assignUsers: 'Gruppenmitglieder verwalten',
  backToLogin: 'Zurück zur Anmeldung',
  cancel: 'Abbrechen',
  change: 'Ändern',
  changePassword: 'Passwort ändern',
  containsInvalidChars: 'Eingabe enthält ungültige Zeichen',
  continue: 'Weiter',
  createAccount: 'Benutzerkonto erstellen',
  currentPassword: 'Aktuelles Passwort',
  currentSession: 'Aktuelle Sitzung',
  delete: 'Löschen',
  deleteGroup: 'Gruppe löschen',
  deleteGroupConfirm: 'Soll die Gruppe {group} wirklich gelöscht werden?',
  deleteUser: 'Benutzer löschen',
  deleteUserConfirm: 'Soll der Benutzer {user} wirklich gelöscht werden?',
  disable2FAForbidden: 'Deaktivierung der Zwei-Faktor Authentisierung ist nicht erlaubt.',
  disable: 'Deaktivieren',
  disabled: 'deaktiviert',
  disableTwoFactorAuthentication: 'Zwei-Faktor Authentisierung deaktivieren',
  editGroup: 'Gruppe bearbeiten',
  editUser: 'Benutzer bearbeiten',
  email: 'E-Mail',
  emailAddress: 'E-Mail-Adresse',
  enabled: 'aktiviert',
  endSession: 'Sitzung beenden',
  enterCode: 'Geben Sie hier den 6-stelligen, durch Ihre Authenticator-App generierten Code ein:',
  firstName: 'Vorname',
  forgotPassword: 'Passwort vergessen?',
  group: 'Gruppe',
  groupId: 'Id',
  groupManagement: 'Gruppenverwaltung',
  groupName: 'Name',
  inputRequired: 'Eingabe erforderlich',
  invalidCode: 'Code ungültig. Bitte versuchen Sie es nochmals.',
  invalidCredentials: 'Benutzername oder Passwort falsch',
  invalidEmail: 'Ungültige E-Mail-Adresse',
  invalidLink: 'Dieser Link ist nicht gültig',
  lastName: 'Nachname',
  location: 'Ort',
  loggedInAs: 'Angemeldet als {user}.',
  loggedOut: 'Abgemeldet',
  loggedOutSuccessfully: 'Sie wurden erfolgreich abgemeldet.',
  loggingOut: 'Sie werden ausgeloggt',
  login: 'Anmelden',
  loginLink: 'Sie haben schon ein Konto? Melden Sie sich an',
  loginWith: 'Melden Sie sich an mit',
  loginWithExistingAccount: 'oder {login}, falls Sie bereits ein Benutzerkonto haben.',
  loginWithoutSSO: 'Ohne SSO anmelden',
  logout: 'Abmelden',
  logoutFailed: 'Abmeldung fehlgeschlagen',
  logoutNotSuccessful: 'Die Abmeldung war nicht erfolgreich.',
  mobileNumber: 'Mobilnummer',
  newPassword: 'Neues Passwort',
  notifyOnboardSuccess: 'Benutzerkonto wurde erfolgreich eingerichtet.',
  notifyPasswordChanged: 'Ihr Passwort wurde erfolgreich geändert.',
  notifyPasswordReset: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
  notifyPasswordResetMail: 'Eine E-Mail mit einem Link zum Zurücksetzen des Passwortes wurde an die angegebene E-Mail-Adresse versendet, falls ein entsprechendes Benutzerkonto existiert.',
  onboardAction: 'Account einrichten',
  onboardTitle: 'Account einrichten',
  organization: 'Organisation',
  orLoginWith: 'oder melden Sie sich an mit',
  password: 'Passwort',
  passwortResetProvideEmail: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen Ihres Passwortes anzufordern.',
  phoneNumber: 'Telefonnummer',
  pleaseEnterPasswordToDisable2FA: 'Bitte geben Sie Ihr Passwort ein, um die Zwei-Faktor Authentisierung zu deaktivieren.',
  pleaseScanBarcode: 'Bitte scannen Sie den folgenden Barcode mit Ihrer Authenticator-App ein oder geben Sie den folgenden Schlüssel manuell ein: {key}.',
  provideNewPassword: 'Bitte geben Sie Ihr neues Passwort ein und bestätigen Sie dieses.',
  recentActivity: 'Letzte Aktivität',
  recommendAuthenticatorApp: "Wenn Sie noch keine Authenticator-App haben, müssen Sie eine auf Ihrem Mobiltelefon installieren. Falls Sie unsicher sind, welche App Sie verwenden sollen, empfehlen wir Ihnen entweder Authy, Google Authenticator oder Microsoft Authenticator.",
  reenterPassword: 'Neues Passwort wiederholen',
  registrationConditions: 'Mit Ihrer Anmeldung bestätigen Sie, dass Sie unsere Nutzungsbedingungen und Datenschutzbestimmungen gelesen und akzeptiert haben.',
  requestLink: 'Link anfordern',
  reset: 'Zurücksetzen',
  reset2FA: '2FA zurücksetzen',
  reset2FAConfirm: 'Soll die Zwei-Faktor Authentisierung für den Benutzer {user} wirklich zurückgesetzt werden?',
  reset2FAConfirmHelp: 'Der Benutzer wird anschliessend 2FA für sein Konto erneut einrichten müssen.',
  resetPassword: 'Passwort zurücksetzen',
  saml: 'SAML',
  save: 'Speichern',
  search: 'Suche',
  selfRegistrationEmailAlreadyRegistered: 'Diese E-Mail-Adresse ist bereits registriert. Melden Sie sich am Anmelde-Formular an.',
  selfRegistrationEmailSent: 'Wir haben ihnen eine E-Mail an {email} gesendet. Klicken Sie auf den Link in der E-Mail um die Registrierung abzuschliessen.',
  selfRegistrationInvalidEmailAddress: 'Die angegebene E-Mail-Adresse ist nicht gültig.',
  selfRegistrationLink: 'Neues Konto erstellen',
  selfRegistrationRegister: 'Registrieren',
  selfRegistrationText: 'Richten Sie hier ein neues Konto ein. Wir senden Ihnen einen Bestätigungslink an die angegebene E-Mail Adresse.',
  selfRegistrationTitle: 'Registrierung',
  sessions: 'Sitzungen',
  setPassword: 'Passwort setzen',
  setupTwoFactorAuthentication: 'Zwei-Faktor Authentisierung einrichten',
  signup: 'Registrieren',
  singleSignOnFailed: 'Automatische Anmeldung fehlgeschlagen. Melden Sie sich durch Eingabe von Benutzernamen und Passwort an.',
  stayLoggedIn: 'Angemeldet bleiben',
  street: 'Strasse',
  switchToLogin: 'melden Sie sich an',
  tfaManagement: 'Zwei-Faktor Authentisierung verwalten',
  tfaNumFailures: 'Fehlgeschlagene 2FA-Versuche',
  title: 'Funktion',
  tryAgain: 'Nochmals versuchen',
  twoFactorAuthentication: 'Zwei-Faktor Authentisierung',
  twoFactorAuthenticationActivated: 'Die Zwei-Faktor Authentisierung wurde aktiviert',
  twoFactorNotSetup: 'Zwei-Faktor Authentisierung ist erforderlich aber nicht eingerichtet. Keine Anmeldung möglich.',
  twoFactorSetup: 'Zwei-Faktor Authentisierung ist erforderlich aber nicht eingerichtet. Bitte folgen Sie den Anweisungen zur Einrichtung der Zwei-Faktor Authentisierung.',
  userManagement: 'Benutzerverwaltung',
  username: 'Benutzername',
  verify: 'Überprüfen',
  wrongPassword: 'Passwort falsch',
  zipCode: 'PLZ',
  approveChangeEmail: 'E-Mail-Adresse ändern',
  changeEmailApproved: 'E-Mail-Adresse wurde geändert.',
  changeEmailDone: 'Fertig',
  changeEmail: 'E-Mail-Adresse ändern',
  successChangeEmailRequest: 'Es wurde eine E-Mail zum Ändern der E-Mail-Adresse an {email} gesendet.',
  inquiryNotPossible: 'Es konnte nicht ermittelt werden, ob der Benutzer gelöscht werden darf.',
  delete_user: 'Konto löschen',
  delete_user_hint: 'Ihr Konto und die damit verbundenen Daten werden gelöscht. Sie können sich mit diesem Konto nicht mehr in der Applikation anmelden.',
  confirm_delete_user_hint: 'Sie sind im Begriff, Ihr Konto zu löschen. Diese Aktion kann nicht mehr rückgängig gemacht werden.',
  confirm_delete_user: 'Konto unwiderruflich löschen',
  danger_zone: 'Gefahrenzone',
}
