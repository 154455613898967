<template>
  <DefaultCard>
    <template #title>
      {{ title }}
    </template>
    <v-form v-if="!enabled && otpauthUrl" @submit.prevent="submit">
      <v-card-text>
        <p>{{ $t('pleaseScanBarcode', { key }) }}</p>
        <p>{{ $t('recommendAuthenticatorApp') }}</p>
        <div class="d-flex justify-center mb-4">
          <QRCode v-if="otpauthUrl" :value="otpauthUrl" :options="{ width: 200 }"></QRCode>
        </div>
        <p>{{ $t('enterCode') }}</p>
        <v-text-field
          v-model="token"
          :error-messages="errorMessages"
          prepend-icon="fa-key"
          name="token"
          label="Code"
          type="text"
          autofocus
          autocomplete="off"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn depressed :to="{ name: 'home' }" color="accent" min-width="100">{{ $t('cancel') }}</v-btn>
        <v-btn depressed type="submit" color="primary" min-width="100">{{ $t('activate') }}</v-btn>
      </v-card-actions>
    </v-form>
    <template v-else-if="enabled">
      <v-card-text>
        {{ $t('twoFactorAuthenticationActivated') }}
      </v-card-text>
      <v-card-actions>
        <v-btn depressed :to="{ name: 'home' }" color="primary">{{ $t('continue') }}</v-btn>
      </v-card-actions>
    </template>
  </DefaultCard>
</template>

<script>
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'
import QRCode from '../components/QRCode.vue'

export default {
  name: 'TOTPSetup',
  components: {
    QRCode,
    DefaultCard,
  },
  data: () => ({
    key: '',
    token: '',
    otpauthUrl: '',
    enabled: false,
    errorMessages: [],
  }),
  computed: {
    title() {
      return this.enabled ? this.$t('twoFactorAuthentication') : this.$t('setupTwoFactorAuthentication')
    },
  },
  mounted() {
    this.renewSecret()
  },
  methods: {
    submit() {
      api
        .post('/api/totp', { key: this.key, token: parseInt(this.token, 10) })
        .then(() => {
          this.enabled = true
        })
        .catch((error) => {
          if (error.response.data.detail === 'Invalid token') {
            this.errorMessages = [this.$t('invalidCode')]
          } else if (error.response.status === 403) {
            this.errorMessages = ['Forbidden. Two-Step verification setup is disabled.']
          }
        })
    },
    renewSecret() {
      api
        .get('/api/totp')
        .then((res) => {
          this.key = res.data.key
          this.otpauthUrl = res.data.url
        })
        .catch((error) => {
          if (error.response.status === 401) this.$router.push({ name: 'login' })
          else if (error.response.status === 400 && error.response.data.detail === 'Already configured') {
            this.enabled = true
          }
        })
    },
  },
}
</script>
