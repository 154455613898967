<template>
  <div v-if="error" class="d-flex align-center justify-center">
    <v-icon x-large color="error">fa-exclamation-circle</v-icon>
  </div>
  <DefaultCard v-else wide no-title>
    <v-card-text v-if="isAuthenticated" data-testid="home-card-text">
      <p class="text-right">
        <v-btn depressed :to="{ name: 'logout' }" color="primary" min-width="100">{{ $t('logout') }}</v-btn>
      </p>
      <i18n path="loggedInAs" tag="p" class="body-1" data-testid="logged-in-message">
        <template v-slot:user>
          <b>{{ userDisplayName }}</b>
        </template>
      </i18n>
      <p v-if="config.change_password_enabled">
        <router-link :to="{ name: 'change-password' }">{{ $t('changePassword') }}</router-link>
      </p>
      <p v-if="config.change_email_enabled">
        <router-link :to="{ name: 'change-email' }">{{ $t('changeEmail') }}</router-link>
      </p>
      <h2>{{ $t('twoFactorAuthentication') }}</h2>
      <v-switch :input-value="tfaEnabled" :label="tfaLabel" :disabled="!tfaSetupAllowed" @click="toggleTOTP"></v-switch>

      <template v-if="apps.length">
        <h2 class="applications">{{ $t('applications') }}</h2>
        <v-list>
          <v-list-item v-for="(app, i) in apps" :key="i" :href="app.url">
            <v-list-item-icon>
              <v-icon>fa-external-link-alt</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="app.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <h2>{{ $t('sessions') }}</h2>
      <v-data-table :headers="tableHeaders" :items="sessions" hide-default-footer disable-sort>
        <template #item.browser="{ item: session }">
          <v-icon small>{{ session.icon }}</v-icon
          >&nbsp;{{ session.browser }}, {{ session.os }}
        </template>
        <template #item.end_session="{ item: session }">
          <v-btn v-if="!session.current" depressed color="accent" small @click="endSession(session.id)">{{
            $t('endSession')
          }}</v-btn>
          <span v-else>{{ $t('currentSession') }}</span>
        </template>
      </v-data-table>
      <v-expansion-panels v-if="config.delete_user_is_enabled" flat class="mt-8">
        <v-expansion-panel>
          <v-expansion-panel-header class="error--text">{{ $t('danger_zone') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h2 class="error--text mb-2">{{ $t('delete_user') }}</h2>
            <p>{{ $t('delete_user_hint') }}</p>
            <DeleteMyAccountDialog>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" depressed color="error" v-on="on">{{ $t('delete_user') }}</v-btn>
              </template>
            </DeleteMyAccountDialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-text v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-card-text>
  </DefaultCard>
</template>

<script>
import UAParser from 'ua-parser-js'
import { DateTime } from 'luxon'
import { branding } from '@/components/mixins'
import DefaultCard from '@/components/DefaultCard'
import DeleteMyAccountDialog from '@/components/DeleteMyAccountDialog'
import api from '@/api'
import redirect from '@/redirect'

const browserIcons = {
  Chrome: 'fab fa-chrome',
  Edge: 'fab fa-edge',
  Firefox: 'fab fa-firefox',
  IE: 'fab fa-internet-explorer',
  IEMobile: 'fab fa-internet-explorer',
  'Mobile Safari': 'fab fa-safari',
  Safari: 'fab fa-safari',
  Opera: 'fab fa-opera',
}

// UBit OneGov Connector (Windows NT 10.0; Win64; x64) OneGovSKSK/3.90.2014.7
const ubitBrowserRegex = [
  [/ubit onegov connector.*OneGov[^/]*([\w.]+)/i],
  [[UAParser.BROWSER.NAME, 'GEVER Laufwerk'], UAParser.BROWSER.VERSION],
]

export default {
  components: { DefaultCard, DeleteMyAccountDialog },
  mixins: [branding],
  data() {
    return {
      isAuthenticated: false,
      username: null,
      loginURL: null,
      fullname: null,
      sessions: [],
      apps: [],
      tfaEnabled: true,
      tfaSetupAllowed: false,
      error: false,
      tableHeaders: [
        { text: 'IP', value: 'ip_address' },
        { text: 'Browser', value: 'browser' },
        { text: this.$t('recentActivity'), value: 'since' },
        { text: '', value: 'end_session' },
      ],
    }
  },
  computed: {
    tfaLabel() {
      return this.tfaEnabled ? this.$t('enabled') : this.$t('disabled')
    },
    userDisplayName() {
      if (this.fullname) {
        return `${this.fullname} (${this.email || this.username})`
      }
      return this.username
    },
  },
  watch: {
    loginURL() {
      if (this.loginURL) {
        const relativeLoginURL = this.loginURL.replace(this.$router.options.base, '')
        if (this.$router.matcher.match(relativeLoginURL).name === 'login') {
          // When login points to the login comopnent in the frontend
          const query = this.$route.query.next ? { next: this.$route.query.next } : {}
          this.$router.push({ path: relativeLoginURL, query })
        } else {
          // When login points to the backend e.g. oidc (openidconnect)
          const url = new URL(this.loginURL, window.location.href)
          if (this.$route.query.next) url.searchParams.set('next', this.$route.query.next)
          redirect(url.href)
        }
      }
    },
  },
  mounted() {
    // Redirect to next URL
    const next = this.$store.state.login.next
    if (next) {
      redirect(next).catch(() => {
        this.fetchInfo()
      })
      return
    }

    this.fetchInfo()
  },
  methods: {
    toggleTOTP() {
      if (this.tfaEnabled) {
        this.$router.push({ name: 'disable-totp' })
      } else {
        this.$router.push({ name: 'setup-totp' })
      }
    },
    fetchInfo() {
      api
        .get('/api/info')
        .then((res) => {
          if (this.$route.query.next && res.data.is_authenticated) {
            redirect(this.$route.query.next)
          }
          this.isAuthenticated = res.data.is_authenticated
          this.loginURL = res.data.login_url
          this.username = res.data.username
          this.email = res.data.email
          this.fullname = res.data.fullname
          this.tfaSetupAllowed = res.data.tfa_setup_allowed
          if (res.data.sessions) {
            this.sessions = res.data.sessions.map((session) => {
              const parser = UAParser(session.user_agent, { browser: ubitBrowserRegex })
              return {
                ...session,
                since: DateTime.fromISO(session.modified).toRelative(),
                browser: parser.browser.name,
                icon: browserIcons[parser.browser.name] || 'fa-dot-circle',
                os: `${parser.os.name} ${parser.os.version}`,
              }
            })
          }
          this.apps = res.data.apps
          this.tfaEnabled = res.data.tfa_enabled
        })
        .catch(() => {
          this.error = true
        })
    },
    endSession(id) {
      api.post('/api/logout', { session_id: id }).then(() => this.fetchInfo())
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchInfo()
    next()
  },
}
</script>

<style scoped>
.logo {
  margin: 0 auto 50px auto;
  display: block;
}
.applications {
  margin-top: 20px;
}
</style>
